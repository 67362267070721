import React, { useState } from "react";
import "./ChartContent.scss";
import { filterData } from "./ChartContentUtility";
import {
  ChartLegend,
  ChartTooltip,
  TimeContent,
  MeanAverageError,
  ChartLinePlot,
  ChartBarChart,
} from "./ChartComponents";
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";

function ChartContent(props: any) {
  const { data, barChartData } = props;
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [inactiveState, setInactiveState] = useState(null);

  const filteredData = filterData(data);
  const { max, delta } = filteredData;
  const dataLength = data.length;

  return (
    <>
      <div className="prediction__box">
        <ChartLegend
          inactiveState={inactiveState}
          setInactiveState={setInactiveState}
        />
        <ChartTooltip
          inactiveState={inactiveState}
          index={hoveredIndex}
          actual={hoveredIndex === null ? 0 : data[hoveredIndex]?.actual}
          prediction={
            hoveredIndex === null ? 0 : data[hoveredIndex]?.prediction
          }
        />

        <ChartLinePlot
          max={max}
          delta={delta}
          dataLength={dataLength}
          setHoveredIndex={setHoveredIndex}
          hoveredIndex={hoveredIndex}
          data={data}
          inactiveState={inactiveState}
        />

        <TimeContent />
      </div>

      <div className="prediction__box" style={{ marginTop: 90 }}>
        <div className="chart-bar-content__title">
          <AiOutlineArrowUp /> <AiOutlineArrowDown />{" "}
          <div style={{ marginLeft: 10, display: "inline" }}>Accurracy</div>
        </div>
        <ChartBarChart data={barChartData} />
        <MeanAverageError mea={300} data={barChartData} />
      </div>
    </>
  );
}

export default ChartContent;
