import React from "react";
import "./CopyCode.scss";
import { IoMdCopy } from "react-icons/io";

function CopyCode(props: any) {
  const { array } = props;

  let string = "";
  array.forEach((item: any) => {
    string = string + item.stringy;
  });

  const copyText = () => {
    navigator.clipboard.writeText(string);
  };

  return (
    <div className="copy-code">
      <div className="copy-code__copy" onClick={() => copyText()}>
        <IoMdCopy size={30} />
      </div>
      {array.map((item: any, idx: any) => {
        return (
          <div className="copy-code__item">
            <div className="copy-code__item-number">{idx + 1}.</div>
            <div style={{ marginLeft: item.shift * 23 }}>{item.stringy}</div>
          </div>
        );
      })}
    </div>
  );
}

export default CopyCode;
