import React from "react";
import NavigationBottomScreen from "../components/NavigationScreen/NavigationBottomScreen";
import NavigationScreen from "../components/NavigationScreen/NavigationScreen";

interface props {
  isBottom?: boolean;
}

function NavigationContainer(props: props) {
  const {isBottom} = props;
  if (isBottom) {
    return (
    <NavigationBottomScreen />
    )
  }
  return <NavigationScreen />;
}

export default NavigationContainer;
