import React from "react";
import NavigationContainer from "../../containers/NavigationContainer";

function CryptoScreen() {
  return (
    <div className="screen">
      <NavigationContainer />
      <h1>Crypto Screen</h1>
    </div>
  );
}

export default CryptoScreen;
