import React from "react";

function NavigationBottomScreen() {
  return (
    <div className="navigation-bottom">
      <div className="underlay">
        <div className="navigation-bottom__background-wave flip"></div>
      </div>

      <h1 style={{ position: "relative" }}>Navigation Bottom</h1>
      <h1 style={{ position: "relative" }}>Navigation Bottom</h1>
      <h1 style={{ position: "relative" }}>Navigation Bottom</h1>
      <h1 style={{ position: "relative" }}>Navigation Bottom</h1>
      <h1 style={{ position: "relative" }}>Navigation Bottom</h1>
    </div>
  );
}

export default NavigationBottomScreen;
