import React, { useState, useEffect } from "react";
import PositionScreen from "./PositionScreen";
import NavigationContainer from "../../containers/NavigationContainer";
import { useScroll, useWindowDimensions } from "../../helpers/index";
import "./Play.scss";

function VerticalScreen() {
  const scrollPos = useScroll();
  const { height, width } = useWindowDimensions();
  const [MousePosition, setMousePosition] = useState({ left: 0, top: 0 });

  const handleMouseMove = (ev: any) => {
    //setMousePosition({left: ev.pageX, top: ev.pageY});
    setMousePosition({ left: ev.pageX, top: ev.pageY });
  };

  const colorArray = ["#18154c", "#ff8177", "#ffe8ae"];

  const divHeight = 550;
  const divOffset = 20 / colorArray.length;

  return (
    <div className="screen red" onMouseMove={(ev) => handleMouseMove(ev)}>
      <div
        style={{
          transform: `translateY(${Math.min(
            scrollPos,
            colorArray.length * 700
          )}px)`,
        }}
        className="spacer layer1"
      ></div>
      <div className="navigation-fixed">
        <NavigationContainer />
      </div>
      <div className="vertical">
        {colorArray.map((item, idx) => {
          const offset = idx * (divHeight - divOffset);
          const delta = idx * 100;
          const maxScroll = Math.min(colorArray.length * 700, scrollPos);
          console.log({ offset, delta, maxScroll });
          return (
            <div
              style={{
                transform: `translateY(${Math.max(
                  maxScroll - offset,
                  delta
                )}px) translateX(${-divOffset * idx}px)`,
              }}
            >
              <PositionScreen
                MousePosition={MousePosition}
                height={height}
                width={width}
                backgroundColor={item}
                scrollPos={scrollPos}
                idx={idx}
              />
            </div>
          );
        })}
      </div>
      <div
        className="vertical__content"
        style={{ marginTop: colorArray.length * 350 }}
      >
        <h1>This is normal text below the scrolling stuff</h1>
        <h1>This is normal text below the scrolling stuff</h1>
        <h1>This is normal text below the scrolling stuff</h1>
        <h1>This is normal text below the scrolling stuff</h1>
        <h1>This is normal text below the scrolling stuff</h1>
        <h1>This is normal text below the scrolling stuff</h1>
        <h1>This is normal text below the scrolling stuff</h1>
      </div>
      <div className="spacer-bottom layer1 flip"></div>
      <NavigationContainer isBottom={true} />
    </div>
  );
}

export default VerticalScreen;
