import { BiCoin } from "react-icons/bi";
import {
  BsReddit,
  BsTwitter,
  BsFillMouseFill,
  BsCurrencyBitcoin,
} from "react-icons/bs";
import { AiOutlineStock, AiFillGold, AiFillApi } from "react-icons/ai";
import { FaRegFileExcel } from "react-icons/fa";

export const navigationItems = [
  { name: "Home", label: "" },
  {
    name: "Crypto",
    label: "Crypto",
    children: [
      {
        name: "Bitcoin",
        label: "bitcoin",
        icon: <BsCurrencyBitcoin size={20} />,
      },
      { name: "Ethereum", label: "ethereum", icon: <BiCoin size={20} /> },
      { name: "Solana", label: "solana", icon: <BiCoin size={20} /> },
    ],
  },
  {
    name: "Stock Market",
    label: "Stock",
    children: [
      { name: "S&P 500", label: "sAndP", icon: <AiOutlineStock size={20} /> },
      { name: "Dow Jones", label: "dowJ", icon: <AiOutlineStock size={20} /> },
      { name: "Gold", label: "gold", icon: <AiFillGold size={20} /> },
      { name: "Tech", label: "tech", icon: <BsFillMouseFill size={20} /> },
    ],
  },
  {
    name: "Trends",
    label: "trends",
    children: [
      { name: "Reddit", label: "Reddit", icon: <BsReddit size={20} /> },
      { name: "Twitter", label: "twitter", icon: <BsTwitter size={20} /> },
    ],
  },
  {
    name: "Developers",
    label: "Api",
    children: [
      { name: "API", label: "Reddit", icon: <AiFillApi size={20} /> },
      { name: "CSV", label: "twitter", icon: <FaRegFileExcel size={20} /> },
    ],
  },
];
