import React, { useState } from "react";
import classNames from "classnames";

export function NavigationItem(props: any) {
  const { changeRoute, item, idx, selected } = props;

  let classContainerItem = classNames("navigation__item", {
    "navigation__item-selected": selected,
  });

  return (
    <button
      onClick={() => changeRoute(item.label)}
      className={classContainerItem}
      key={idx + "123"}
    >
      {item.name}
    </button>
  );
}

export function NavigationChildren(props: any) {
  const { changeRoute, item, selected, idx } = props;
  const { children } = item;

  let classContainerItem = classNames("navigation__item", {
    "navigation__item-selected": selected,
  });

  return (
    <div className="navigation__wrapper-children">
      <button className={classContainerItem} key={idx + "123"}>
        {item.name}
      </button>

      <div className="navigation__wrapper-children__dropdown">
        {children.map((childrenItem: any, idx: any) => {
          return (
            <div
              onClick={() => changeRoute(childrenItem.label)}
              className="navigation__wrapper-children__dropdown__item"
            >
              {childrenItem.icon}{" "}
              <div style={{ marginLeft: 6 }}>{childrenItem.name}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export function NavigationChildrenMobile(props: any) {
  const { changeRoute, item, selected, setSelected, idx } = props;
  const { children } = item;
  const isSelected = selected === idx && children;

  const clickItem = (label: any) => {
    //
    if (!children) {
      changeRoute(label);
    } else if (isSelected) {
      setSelected(null);
    } else {
      setSelected(idx);
    }
  };

  const clickChild = (label: any) => {
    changeRoute(label);
  };

  let divHeight = 40;
  if (children && isSelected) {
    divHeight = children.length * 45 + 55;
  }

  return (
    <div
      className={`navigation__mobile-item ${
        isSelected ? "navigation__mobile-item-selected" : ""
      }`}
      style={{ height: divHeight }}
    >
      <div
        className={`navigation__mobile-item-title ${
          isSelected ? "navigation__mobile-item-title-selected" : ""
        }`}
        onClick={() => clickItem(item.label)}
      >
        {item.name}
      </div>

      {children &&
        children.length > 0 &&
        children.map((child: any, idx: any) => {
          return (
            <div
              className={`navigation__mobile-children ${
                isSelected ? "navigation__mobile-children-active" : ""
              }`}
              onClick={() => clickChild(item.label)}
            >
              {child.icon} <div style={{ marginLeft: 6 }}>{child.name}</div>
            </div>
          );
        })}
    </div>
  );
}
