import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface routeState {
  data: any;
  email: string;
  username: string;
  token: string;
}

const initialState: routeState = {
  data: null,
  email: "",
  username: "",
  token: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: (state) => {
      state.token = "";
      state.email = "";
      state.username = "";
      state.data = "";
    },
  },
});

export const { logout } = userSlice.actions;

export const stateData = (state: RootState) => ({
  state: state,
});

export const userData = (state: RootState) => ({
  token: state.user.token,
  email: state.user.email,
  username: state.user.username,
  data: state.user.data,
});

export default userSlice.reducer;
