import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomeContainer from "./HomeContainer";
import CryptoContainer from "./CryptoContainer";
import PlayContainer from "./PlayContainer";
import RedditContainer from "./RedditContainer";

const Router = function Router(props: any) {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomeContainer />} />
        <Route path="/crypto" element={<CryptoContainer />} />
        <Route path="/play" element={<PlayContainer />} />
        <Route path="/reddit" element={<RedditContainer />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
