import React, { useState } from "react";

const PositionScreen = (props: any) => {
  const { MousePosition, width, scrollPos, idx } = props;
  const { left, top } = MousePosition;
  const [isHovered, setIsHovered] = useState(false);

  const deltaY = 270 - top + idx * 900 + Math.max(0, scrollPos - idx * 870);
  const deltaYLog =
    deltaY > 0 ? -1 * Math.log2(Math.abs(deltaY)) : Math.log2(Math.abs(deltaY));

  const deltaX = left - width / 2;
  const deltaXLog =
    deltaX > 0 ? Math.log2(Math.abs(deltaX)) : -1 * Math.log2(Math.abs(deltaX));

  return (
    <div
      className="play-box"
      style={{
        backgroundColor: props.backgroundColor,
        marginTop: idx === 0 ? 100 : 0,
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => console.log("hell0")}
    >
      <h1
        style={{
          transform: `translateY(${isHovered ? deltaYLog : 0}px) translateX(${
            isHovered ? deltaXLog : 0
          }px)`,
        }}
        className="play-box-title"
      >
        This is a long story that I want to read
      </h1>
    </div>
  );
};

export default PositionScreen;
