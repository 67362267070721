import React, { useEffect } from "react";
import HomeScreen from "../components/HomeScreen/HomeScreen";
import { useAppDispatch } from "../hooks/hooks";
import { cryptoFindData } from "../redux/reducers/cryptoSlice";

function HomeContainer() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(cryptoFindData());
  }, []);
  return <HomeScreen />;
}

export default HomeContainer;
