import React from "react";
import ChartContent from "./ChartContent/ChartContent";

import "./Prediction.scss";

const data2 = Array.from(Array(60).keys()).map((item: any, idx: any) => ({
  index: 59 - item,
  actual: Math.floor(Math.random() * 1000) + 60000,
  prediction: Math.floor(Math.random() * 1000) + 60000,
}));

const data = [
  { time: "1 month", accuracy: 0.33, mea: 301 },
  { time: "14 days", accuracy: 0.49, mea: 302 },
  { time: "7 days", accuracy: 0.51, mea: 303 },
  { time: "24 hour", accuracy: 0.55, mea: 304 },
  { time: "7 hour", accuracy: 0.53, mea: 305 },
  { time: "1 hour", accuracy: 0.49, mea: 306 },
  { time: "30 min", accuracy: 0.71, mea: 307 },
];

function PredictionContent() {
  return (
    <div
      style={{ position: "relative", marginBottom: 110 }}
      className="prediction"
    >
      <div className="prediction__headers">
        <div className="prediction__header1">
          AI and Crypto, Stocks, Trends (Twitter, Reddit), and more
        </div>
        <div className="prediction__header2">Predict Bitcoin price</div>
      </div>

      <ChartContent data={data2} barChartData={data} />
    </div>
  );
}
//
export default PredictionContent;
