import React from "react";
import NavigationContainer from "../../containers/NavigationContainer";
import PredictionContent from "./ScreenContent/PredictionContent/PredictionContent";
import "./Home.scss";
import APIContent from "./ScreenContent/APIContent/APIContent";
import CSVContent from "./ScreenContent/CSVContent/CSVContent";

function HomeScreen() {
  return (
    <div className="screen home">
      <div className="underlay">
        <div className="home__background-wave"></div>
      </div>
      <NavigationContainer />

      <PredictionContent />

      <CSVContent />

      <APIContent />

      <NavigationContainer isBottom={true} />
    </div>
  );
}

export default HomeScreen;

//
//       <h1>Home Screen</h1>

//       <div className="blue" style={{ width: "100%", flexGrow: 1 }}>
//         <h1>Content</h1>
//       </div>
//       <NavigationContainer isBottom={true} />
