import axios from "axios";

let url = process.env.REACT_APP_PROD_URL;
if (true) {
  url = process.env.REACT_APP_LOCAL_URL;
}

interface requestProps {
  endPoint: string;
  arg: any;
}

interface requestAuthorizedProps {
  endPoint: string;
  arg: any;
}

export const request = (props: requestProps) => {
  const { endPoint, arg } = props;
  return axios({
    method: "post",
    url: `${url}${endPoint}`,
    data: arg,
  });
};

export const requestAuthorized = (props: requestAuthorizedProps) => {
  const { endPoint, arg } = props;
  return axios({
    method: "post",
    url: `${url}${endPoint}`,
    headers: {
      Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Im11cnJheUBnbWFpbC5jb20iLCJpYXQiOjE2NDE3ODI2ODV9.PMi7QrqScjvysRulBqj1dKeo9w1sn0P0rJP2T87129k`,
    },
    data: arg,
  });
};
