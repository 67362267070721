import { useState } from "react";
import classNames from "classnames";
import "./Navigation.scss";
import { IoMenuOutline } from "react-icons/io5";
import { RiCloseFill } from "react-icons/ri";
import { useNavigate, useLocation } from "react-router-dom";
import {
  NavigationChildren,
  NavigationItem,
  NavigationChildrenMobile,
} from "./NavigationContent";
import { navigationItems } from "./NavigationUtil";

interface LocationParams {
  pathname: string;
  state: any;
  search: string;
  hash: string;
  key: string;
}

function NavigationScreen() {
  const [openMenu, setOpenMenu] = useState(false);
  const [selectedMobileView, setSelectedMobileView] = useState(null);
  const navigate = useNavigate();
  // @ts-ignore: Unreachable code error
  const location = useLocation<LocationParams>();

  const changeRoute = (route: string) => {
    navigate("/" + route);
    setOpenMenu(false);
  };

  return (
    <div className="navigation">
      <div className="navigation__header">
        <div className="navigation__logo-wrapper">
          <div className="navigation__logo"></div>
          <div className="navigation__logo2"></div>
          <h2 className="navigation__logo-title">nexty</h2>
        </div>

        <div className="navigation__items">
          {navigationItems.map((item: any, idx: any) => {
            let currentLabel = "/" + item.label;

            if (item?.children) {
              return (
                <NavigationChildren
                  idx={idx}
                  selected={currentLabel === location.pathname}
                  item={item}
                  changeRoute={changeRoute}
                />
              );
            }
            return (
              <NavigationItem
                selected={currentLabel === location.pathname}
                idx={idx}
                item={item}
                changeRoute={changeRoute}
              />
            );
          })}
        </div>
        <div
          className="navigation__menu-item"
          onClick={() => setOpenMenu((prev) => !prev)}
        >
          {!openMenu ? <IoMenuOutline size={30} /> : <RiCloseFill size={30} />}
        </div>
      </div>

      {openMenu && (
        <div className="navigation__items-column">
          <div className="navigation-mobile-background"></div>
          <div className="navigation-mobile-background-top"></div>
          <div
            className="navigation__menu-item"
            onClick={() => setOpenMenu((prev) => !prev)}
            style={{ marginBottom: 25 }}
          >
            <RiCloseFill size={35} />
          </div>
          {navigationItems.map((item: any, idx: any) => {
            let currentLabel = "/" + item.label;

            return (
              <NavigationChildrenMobile
                changeRoute={() => changeRoute(item.label)}
                key={idx + "mobileview"}
                item={item}
                setSelected={setSelectedMobileView}
                selected={selectedMobileView}
                idx={idx}
                currentlySelected={currentLabel === location.pathname}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}

export default NavigationScreen;
