import React from "react";
import CopyCode from "../../../Common/CopyCode/CopyCode";
import "./API.scss";

function APIContent() {
  return (
    <div style={{ marginBottom: 800 }} className="api-content">
      <div className="api-content__title">Nexty API's</div>

      <div className="api-content__row">
        <div className="api-content__item">
          <div className="api-content__subtitle">
            Get the predicted future Bitcoin price (1 minute)
          </div>
        </div>
        <div className="api-content__item">
          <div className="api-content__code">
            <div className="api-content__code-top">BASE URL</div>

            <CopyCode
              array={[
                {
                  stringy: "https://api.nextcoin.com/bts/prediction",
                  shift: 0,
                },
              ]}
            />
          </div>
        </div>
      </div>

      <div
        className="api-content__row"
        style={{ marginTop: 100, marginBottom: 200 }}
      >
        <div className="api-content__item">
          <div className="api-content__subtitle">Response:</div>
          <div
            className="api-content__subtitle"
            style={{
              fontSize: 20,
              fontWeight: 500,
              marginTop: 20,
              fontFamily: "OpenSans-Regular",
            }}
          >
            The time provided is Unix Timestamp (seconds). The current and
            predicted Bitcoin price is USD ($) amount.{" "}
          </div>
        </div>
        <div className="api-content__item">
          <div className="api-content__code">
            <div className="api-content__code-top">
              <div>Sample Response</div>
              <div>JSON</div>
            </div>

            <CopyCode
              array={[
                { stringy: "data = {", shift: 0 },
                { stringy: "time: 1642974081,", shift: 1 },
                { stringy: "predictTime: 1642992081,", shift: 1 },
                { stringy: "bitcoin: {", shift: 1 },
                { stringy: "current: 35400,", shift: 2 },
                { stringy: "predicted: 35423", shift: 2 },
                { stringy: "},", shift: 1 },
                { stringy: "}", shift: 0 },
              ]}
            />
          </div>
        </div>
      </div>
      <div className="api-content__bottom-text">
        👋 Nexty is a very new service and we’re currently building up our
        available API’s. If you’d like to suggest an API. Please reach out here.
        We love feedback from our users. 👋
      </div>
    </div>
  );
}

export default APIContent;
