import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import { useHover } from "../../../../../hooks/hooks";
import { BsFillFileBarGraphFill } from "react-icons/bs";

export function ChartLegend(props: any) {
  const { inactiveState, setInactiveState } = props;

  const changeInactiveState = (type: number) => {
    if (inactiveState === type) {
      setInactiveState(null);
    } else {
      setInactiveState(type);
    }
  };

  return (
    <div className="chart-legend">
      <div className="chart-legend__column">
        <div onClick={() => changeInactiveState(1)}>Prediction</div>
        <div onClick={() => changeInactiveState(2)}>Actual</div>
      </div>
      <div className="chart-legend__column">
        <div
          className={`chart-legend__box ${
            inactiveState === 1 ? "chart-legend__box-inactive" : ""
          }`}
          onClick={() => changeInactiveState(1)}
        ></div>
        <div
          className={`chart-legend__box2 ${
            inactiveState === 2 ? "chart-legend__box-inactive2" : ""
          }`}
          onClick={() => changeInactiveState(2)}
        ></div>
      </div>
    </div>
  );
}

export function ChartDetectHover(props: any) {
  const { index, setHoveredIndex } = props;
  const [hovered, eventHandlers] = useHover();

  useEffect(() => {
    if (hovered) {
      setHoveredIndex(index);
    } else {
      setHoveredIndex(null);
    }
  }, [hovered]);

  return <div {...eventHandlers} className={`chart-detect-hover`}></div>;
}

export function ChartTooltip(props: any) {
  const { index, actual, prediction, inactiveState } = props;

  return (
    <div
      className={`chart-tooltip ${
        index || index === 0 ? "chart-tooltip__active" : ""
      }`}
    >
      {inactiveState !== 1 && (
        <div className="chart-tooltip__column">
          $ {actual.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </div>
      )}
      {inactiveState !== 2 && (
        <div className={`chart-tooltip__column2`}>
          $ {prediction.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </div>
      )}
      {inactiveState === null && (
        <div
          className="chart-tooltip__column"
          style={{ marginTop: 10, fontWeight: 700 }}
        >
          % {(((prediction - actual) / actual) * 100).toFixed(2)}
        </div>
      )}
    </div>
  );
}

export function ChartToggle(props: any) {
  const { setChartType, chartType } = props;

  return (
    <div
      className={`chart-toggle`}
      onClick={() => setChartType(chartType === 1 ? 2 : 1)}
    >
      <BsFillFileBarGraphFill size={40} />
    </div>
  );
}

export function ChartLinePlot(props: any) {
  const {
    data,
    max,
    delta,
    dataLength,
    hoveredIndex,
    setHoveredIndex,
    inactiveState,
  } = props;
  const divRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    setTimeout(() => {
      // @ts-ignore
      divRef.current.scrollLeft += 9000;
    }, 500);
  }, []);

  return (
    <div
      className="prediction__box-chart"
      style={{ marginLeft: 10 }}
      ref={divRef}
    >
      {data.map((item: any, idx: any) => {
        const { actual, prediction, index } = item;
        let actualTop = ((max - actual) / delta) * 260 + 20;
        let predictionTop = ((max - prediction) / delta) * 260 + 20;

        let actualTopPrev = 0;
        let predictionTopPrev = 0;

        if (idx > 0) {
          actualTopPrev = ((max - data[idx - 1].actual) / delta) * 260 + 20;
          predictionTopPrev =
            ((max - data[idx - 1].prediction) / delta) * 260 + 20;
        }

        return (
          <div
            className="chart-content"
            style={{ marginRight: dataLength - 1 === idx ? 40 : 0 }}
            key={`chart-item-${index}`}
          >
            <ChartDetectHover
              actual={actual}
              prediction={prediction}
              index={index}
              setHoveredIndex={setHoveredIndex}
            />
            <div className="chart-content__chart">
              {inactiveState !== 1 && (
                <div
                  className={`chart-content__actual ${
                    hoveredIndex === index ? "chart-content__actual-active" : ""
                  }`}
                  style={{ top: actualTop }}
                ></div>
              )}
              {inactiveState !== 2 && (
                <div
                  className={`chart-content__prediction ${
                    hoveredIndex === index
                      ? "chart-content__prediction-active"
                      : ""
                  }`}
                  style={{ top: predictionTop }}
                ></div>
              )}
              {idx > 0 && (
                <>
                  <svg
                    height="350"
                    width="150"
                    style={{ position: "absolute" }}
                  >
                    {inactiveState !== 1 && (
                      <line
                        x1="0"
                        y1={actualTopPrev + 7}
                        x2="150"
                        y2={actualTop + 7}
                        style={{ stroke: "black", strokeWidth: 1 }}
                      />
                    )}
                    {inactiveState !== 2 && (
                      <line
                        x1="0"
                        y1={predictionTopPrev + 7}
                        x2="150"
                        y2={predictionTop + 7}
                        style={{ stroke: "#1b817a", strokeWidth: 1 }}
                      />
                    )}
                  </svg>
                </>
              )}
            </div>
            <div
              className={`chart-content__axis ${
                index === hoveredIndex ? "chart-content__axis-active" : ""
              }`}
            >
              10:{59 - index} AM
            </div>
          </div>
        );
      })}
    </div>
  );
}

export const TimeContent = () => {
  const [seconds, setSeconds] = useState(0);
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  useEffect(() => {
    const interval = setInterval(() => {
      const d = new Date();
      let seconds_ = d.getSeconds();
      setDate(moment().format("MMM Do YY"));
      setSeconds(seconds_);
      setTime(moment().format("LT"));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="prediction__box-countdown">
      <div
        style={{
          fontSize: 30,
          marginBottom: 15,
        }}
      >
        Next Prediction
      </div>
      <div
        style={{
          fontSize: 50,
          marginBottom: 15,
        }}
      >
        {60 - seconds}
      </div>
      <div style={{ fontSize: 20 }}>{date}</div>
      <div style={{ fontSize: 20 }}>{time}</div>
    </div>
  );
};

export function ChartBarChart(props: any) {
  const { data } = props;
  const divRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    setTimeout(() => {
      // @ts-ignore
      divRef.current.scrollLeft += 4000;
    }, 500);
  }, []);

  return (
    <div
      className="prediction__box-chart"
      style={{ marginLeft: 10 }}
      ref={divRef}
    >
      {data.map((item: any, idx: any) => {
        let divHeight = item.accuracy * 175 * 2;
        divHeight = Math.min(divHeight, 280);
        divHeight = Math.max(divHeight, 35);
        return (
          <div className="chart-bar-content" key={`chart-bar-item-${idx}`}>
            <div></div>
            <div className="chart-bar-content__middle-line"></div>
            <div
              className="chart-bar-content__bar-value"
              style={{ height: divHeight * 1.05 }}
            >
              % {Math.round(item.accuracy * 100).toFixed()}
            </div>
            <div
              className="chart-bar-content__bar"
              style={{ height: divHeight - 27 }}
            ></div>
            <div
              className={`chart-content__axis`}
              style={{ textAlign: "center", borderColor: "#b8b8b8" }}
            >
              {item.time}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export const MeanAverageError = (props: any) => {
  const { data } = props;
  const [time, setTime] = useState(data[data.length - 1].time);

  let value = 0;
  data.forEach((item: any) => {
    if (item.time === time) {
      value = item.mea;
    }
  });

  return (
    <div className="mean-average-error">
      <div
        style={{
          fontSize: 33,
          marginBottom: 25,
        }}
      >
        $ {value}
      </div>
      <div style={{ marginBottom: 10 }}>Mean Average Error</div>
      <select
        className="mea-select"
        name="cars"
        id="cars"
        value={time}
        onChange={(e) => setTime(e.target.value)}
      >
        {data.map((item: any, idx: any) => {
          return (
            <option className="mea-select-option" value={item.time}>
              {item.time}
            </option>
          );
        })}
      </select>
    </div>
  );
};
