import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { requestAuthorized } from "../api/request";
import { RootState } from "../store";

export const cryptoFindData = createAsyncThunk("crypto/find", async (_) => {
  const endPoint = "data/find";
  const response = await requestAuthorized({
    arg: {
      hour: 0.25,
      project: { time: 1, hour: 1, minute: 1 },
    },
    endPoint,
  });
  return response;
});

export interface routeState {
  object: {
    isLoading: boolean;
    error: any;
  };
  bitcoinCandleStick: any;
}

const initialState: routeState = {
  object: {
    isLoading: false,
    error: null,
  },
  bitcoinCandleStick: [],
};

export const cryptoSlice = createSlice({
  name: "crypto",
  initialState,
  reducers: {
    reset: (state) => {
      state.bitcoinCandleStick = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(cryptoFindData.pending, (state) => {
        state.object.isLoading = true;
        state.object.error = null;
      })
      .addCase(cryptoFindData.fulfilled, (state, action: any) => {
        state.object.isLoading = false;
        state.bitcoinCandleStick = action.payload.data;
      })
      .addCase(cryptoFindData.rejected, (state, action) => {
        state.object.isLoading = false;
        state.object.error = action.error;
      });
  },
});

export const { reset } = cryptoSlice.actions;

export const cryptoData = (state: RootState) => ({
  object: state.crypto.object,
  bitcoinCandleStick: state.crypto.bitcoinCandleStick,
});

export default cryptoSlice.reducer;
