import React from "react";
import "./CSVContent.scss";
import ScrollContainer from "react-indiana-drag-scroll";

function CSVColumn(props: any) {
  return (
    <>
      {Array.from(Array(15).keys()).map((item: any, idx: any) => {
        return <div className="csv-content__csv-column">{idx}</div>;
      })}
    </>
  );
}

function CSVContent() {
  return (
    <div className="csv-content">
      <div className="csv-content__title">CSV Data</div>
      <ScrollContainer
        className="csv-content__csv-container"
        hideScrollbars={false}
      >
        {Array.from(Array(60).keys()).map((item: any, idx: any) => {
          return (
            <div className="csv-content__csv-row" style={{ width: 15 * 105 }}>
              <CSVColumn />
            </div>
          );
        })}
      </ScrollContainer>
    </div>
  );
}

export default CSVContent;
